import axios from 'axios';
import { ActionContext } from 'vuex';

import { Feature } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { Field } from '@/shared/api/rest/models';
import { FieldContour } from '@/shared/api/rest/models/field';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';
import { RootState } from '@/store/types';

import { FieldsState, ProductRequestBody } from './types';

export async function fetchNdvi(productsWithContour: ProductRequestBody[]): Promise<Record<string, Feature>> {
  const { data } = await axios.post('/admin/sen4/multiPolyMean', {
    indexType: 'DNN_NDVI',
    products: productsWithContour,
  });
  return data.products;
}

export async function fetchMBI(productsWithContour: ProductRequestBody[]): Promise<Record<string, Feature>> {
  const { data } = await axios.post('/admin/sen4/multiPolyMean', {
    indexType: 'MBI',
    products: productsWithContour,
  });
  return data.products;
}

export const modelFieldContour = {
  fieldId: null,
  geometry: null,
  id: null,
};

export async function resolveProcessOrderId(entry: Field, context: ActionContext<FieldsState, RootState>) {
  const { state, rootState, rootGetters } = context;
  let processOrderId = null;
  if (entry.processOrderId != null) {
    ({ processOrderId } = entry);
  } else if (state.data[entry.id] != null && state.data[entry.id].processOrderId != null) {
    ({ processOrderId } = state.data[entry.id]);
  } else if (rootGetters['auth/currentProcessOrders'].length === 1) {
    processOrderId = rootGetters['auth/currentProcessOrders'][0].id;
  } else if (getDescendantProp(entry, 'processOrder.companyId') != null) {
    let selectedCompanyId = null;
    Object.values(rootState.auth.companiesById).some((company) => {
      if (company.id === getDescendantProp(entry, 'processOrder.companyId')) {
        selectedCompanyId = company.id;
        return true;
      }
      return false;
    });
    const processOrder = rootGetters['auth/processOrderByCompanyIdAndNameAndType'](
      selectedCompanyId,
      rootState.auth.currentProcessOrderName,
    );
    processOrderId = processOrder.id;
  }
  return {
    ...entry,
    processOrderId,
    processOrder: rootGetters['auth/processOrdersById'][processOrderId],
  };
}

export function resolveCropId(entry: Field, context: ActionContext<FieldsState, RootState>): Field {
  const { rootState, rootGetters } = context;
  if (entry.cropId == null) {
    return entry;
  }
  const crop = rootState.products.crops.data[entry.cropId];
  if (crop == null || entry.processOrderId == null) {
    return { ...entry, cropId: null };
  }
  if (rootState.auth.currentCompanies.length === 1) {
    return entry;
  }
  const fieldCompanyId = rootGetters['auth/processOrdersById'][entry.processOrderId].companyId;
  if (crop.companyId == null || crop.companyId === fieldCompanyId) {
    return entry;
  }
  if (crop.relatedMaterialId == null) {
    return { ...entry, cropId: null };
  }
  let resolvedCropId = null;
  Object.values(rootState.products.crops.data).some((currentCrop) => {
    if (currentCrop.relatedMaterialId === crop.relatedMaterialId && currentCrop.companyId === fieldCompanyId) {
      resolvedCropId = currentCrop.id;
      return true;
    }
    return false;
  });
  return { ...entry, cropId: resolvedCropId };
}

/**
 * Compare two field contours. Returns true if they have the same tstamp.
 */
export function compareFieldContours(contourA: FieldContour, contourB: FieldContour) {
  if (contourA === contourB || (contourA == null && contourB == null)) {
    return true;
  }
  if (contourA == null || contourB == null || contourA.id !== contourB.id) {
    return false;
  }
  return contourA.tstamp === contourB.tstamp;
}

export function resolveAdditionalData(
  entry: {
    'additionalData.cadastralCommunity': string;
    'additionalData.plots': string;
    'additionalData.owner': string;
    'additionalData.rightOfUse': string;
    'additionalData.overrideFertilizerClass': string;
  },
  oldEntry: Field,
): {
  cadastralCommunity: string;
  plots: string;
  owner: string;
  rightOfUse: string;
  overrideFertilizerClass: string;
} {
  return {
    cadastralCommunity: entry['additionalData.cadastralCommunity'] ?? oldEntry.additionalData?.cadastralCommunity ?? '',
    plots: entry['additionalData.plots'] ?? oldEntry.additionalData?.plots ?? '',
    owner: entry['additionalData.owner'] ?? oldEntry.additionalData?.owner ?? '',
    rightOfUse: entry['additionalData.rightOfUse'] ?? oldEntry.additionalData?.rightOfUse ?? '',
    overrideFertilizerClass:
      entry['additionalData.overrideFertilizerClass'] ?? oldEntry.additionalData?.overrideFertilizerClass ?? '',
  };
}
